:root{
  --blue: #224c9f;
  --blueTranslucent: #224c9fc2;
  --blueDarker: #16326c;
  --blueHover: #366fe2;
  --letBlue: #23a6d5;
  --letBlueHighlight: #72beda;
  --veryLightBlue: rgb(200, 200, 255);
  --white: whitesmoke;
  --whiteBackground: rgb(244, 244, 244);
  --whiteTraslucent: rgba(245, 245, 245, 0.6);
  --black: #1f1f1f;
  --shadow: rgb(0, 0, 0, 0.3);
  --shadowStrong: rgb(0, 0, 0, 0.9);

  --linearGradientDarkBlue: #16336cde; 
  --linearGradientBlue:#224c9ff4; 
  --linearGradientLetBlue:#23a5d5c5; 
  --linearGradientAquamarine:#23d5abf4; 

  --whatsAppButton: #25d366;
  --whatsAppButtonHover: #00ff5e;
}
@font-face {
  font-family: 'Nexa-regular';  
  src: url(../fonts/nexa_regular.otf);
}

* {
  margin: 0;
  padding: 0;
  font-size: calc(6px + 0.6vmax);
  /* font-family: 'Nexa-regular'; */
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
}
/* h1, h2 {
  font-family: 'Montserrat', sans-serif;

} */
input::placeholder, textarea::placeholder{
  color: rgba(255, 255, 255, 0.3);
}
@media (max-width: 300px){
  
  .welcome__title{
    font-size: 2.5rem !important;
  }
  .welcome__img{
    height: fit-content;
    width: 200px ;
    /* position: absolute; */
    top: 30vh;
    left: 65%;
  }

  .carousel_button{
    /* top: none; */
    bottom: 0 !important;
  }

  .technical_support__tittle{
    font-size: 2rem !important;
  }
  .contact-section__1 > p, .contact-section__2 > p, .contact-section__3 > p, .contact-section__4 > p, .contact-section__5 > p{
    font-size: 0.8rem;
  }
  .contact-section__1 > p > b, .contact-section__2 > p > b, .contact-section__3 > p > b, .contact-section__4 > p > b, .contact-section__5 > p > b{
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 640px) {
  .welcome, .technical_support{
    padding: 10vh 0 5vh 0 !important;
  }
  
  .header {
    position: fixed;
    top: auto;
    bottom: 0 !important;
    height: 5vh;
    padding: 1rem 0;
    background-color: var(--shadow);
    backdrop-filter: blur(10px);

  }

  .header__logo {
    position: absolute;
    left: 2rem;
    cursor: pointer;
    width: 40vw;
    height: fit-content;
  }

  .header__hamburger-button {
    position: absolute;
    right: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    /* width: 3rem; */
    height: 3rem;
    cursor: pointer;
    line-height: 1rem;
    font-size: 1rem;

    color: var(--white);
    fill: var(--white);
    background-color: transparent;
    border: solid 1px var(--white);
    border-radius: 1rem;
  }
  .header__hamburger-button > span {
    height: 100%;
  }
  .header__hamburger-button > svg {
    margin-right: 0.5rem;
  }

  .navbar{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95vh;
    min-width: 35%;
    bottom: calc(5vh + 2rem);
    right: 0;
    background-color: var(--shadowStrong);
    transition: 0.2s ease-out;
  }
  .navbar--closed{
    transform: translateX(100%);
  }
  .navbar--open{
    transform: translateX(0%);

  }


  .navbar > .navbar__button,
  .header__logIn {
    padding: 1rem 0 1rem 0.5rem !important;
    font-size: 1.5rem;
    text-align: center;
  }

  .header__closeModal{
    display: none !important;
    border: solid 1px var(--white) !important;
    padding: 0.5rem 1rem !important;
    position: absolute;
    bottom: 1rem;
  }

  /* .navbar__left{
    margin-bottom: 7vh;
  } */
  .footer{
    padding: 5vh 0 10vh 0 !important;
  }

  #contact-section{
    padding: 0 0 10vh 0 !important;

  }

  .presentation__text{
    /* text-align: left !important; */
    font-size: 1.5rem !important;
  }
  h3.presentation__text{
    text-align: left !important;
    font-size: 2rem !important;
  }

}
@media only screen and (min-width: 641px){
  .header__hamburger-button{
    display: none;
  }
  .header__logo {
    width: 12rem;
    height: fit-content;
    margin: 0 0.5rem 0 auto;
    cursor: pointer;
  }


  .navbar {
    display: flex;
    flex-wrap: nowrap;
    align-self: center;
    justify-self: center;
    margin: auto auto auto 0;
    height: 100%;
    padding: 0;
  }
  .header__closeModal{
    display: none !important;
  }
}
@media (max-width: 1100px) {

  .App {
    color: var(--black);
    text-align: center;
    background: linear-gradient(135deg, var(--linearGradientDarkBlue), var(--linearGradientLetBlue));
  }

  .header {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    /* top: 0; */
    width: 100%;
    height: 5vh;
    padding: 1rem 0;
    color: var(--white);
    
  }
  .header--background {
    background-color: var(--shadow);
    backdrop-filter: blur(10px);
    }
  
    .header__logo{
      height: 50px;
      width: auto;
    }
  

  .navbar > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    padding: 0.5rem 0.5rem;
    color: inherit;
    background-color: transparent;
    border: none;
    border-radius: 1rem;
    transition: 0.3s;
    font-size: 1.5rem;
  }
  .navbar > button:hover {
    cursor: pointer;
    background-color: var(--blueHover);
  }
  .navbar>button > svg {
    width: 1.5rem;
    margin-left: 0.5rem;
    fill: var(--white);
  }
  .header__logIn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    color: inherit;
    text-decoration: none;
    padding: 0.5rem 0.5rem;
    font-size: 1.5rem;

    border-radius: 1rem;
    transition: 0.3s;

  }
  .header__logIn:hover {
    cursor: pointer;
    background-color: var(--blueHover);
  }
  
  .header__logIn > svg {
    width: 1.5rem;
    margin-left: 0.5rem;
    justify-content: center;
    align-items: center;
    fill: var(--white);
  }

  .welcome {
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 80vh;
    padding: 20vh 0 0 0;
    color: var(--white);
    
    /* background-size: 100% 100%; */
    /* overflow: hidden; */
  }
  @keyframes rotation {
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(359deg);
    }
  }

  /* .welcome__background{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    top:0;
    left: 0;
    background:  url(../imgs/abstract.jpg);
    background-size: cover;
    animation: rotation 60s ease infinite;
  } */

  
  .welcome__title {
    padding: 0vh 0;
    font-size: 3rem;
    text-align: center;
    width: calc(90% - 2rem);
    max-width: 600px;
    margin: 0 auto;
    text-shadow: 0.5rem 0.5rem 0.5rem var(--blueDarker);

  }
  .welcome__img{
    width: 90%;
    height: fit-content;
    filter: drop-shadow( 0.5rem 0.5rem 0.5rem var(--blueDarker))
  }

  .presentation {
    padding: 4rem 0;
  }
  .presentation__text {
    font-size: 1.5rem;
    width: calc(90% - 2rem);
    max-width: 600px;
    padding: 1rem;
    margin: 0 auto 1rem auto;
    text-align: justify;
    hyphens: auto;
    /* border-radius: 2rem; */
    /* background-color: var(--blueTranslucent); */
    /* box-shadow: 0 0 0rem var(--shadow); */
  }
  h3.presentation__text{
    font-size: 2em;
  }
  .presentation__video {
    margin-top: 2rem;
    height: fit-content;
    min-height: 300px;
    width: calc(90% - 2rem);
    max-width: 600px;
    border-radius: 1rem;
  }
  .presentation__afip-img{
    margin-top: 10rem;
    height: fit-content;
    width: calc(90% - 2rem);
    max-width: 600px;
    border-radius: 1rem;
  }

  .carousel {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto 4rem auto;
    width: calc(90% - 2rem);
    max-width: 600px;
    height: 20vh;
    /* overflow: hidden; */
    transition: 0.3s ease-in-out;
    /* background-color: #00ff5e; */
  }

  .carousel_button {
    position: absolute;
    bottom: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 3rem;
    width: 3rem;

    background-color: var(--blueTranslucent);
    color: var(--white);
    border: none;
    border-radius: 50%;
    transition: ease 0.2s;
    z-index: 2;

  }
  .carousel_button:hover {
    cursor: pointer;
    background-color: var(--blueDarker);
    /* color: var(--blueDarker); */
  }
  .carousel_button--left {
    left: 0
  }
  .carousel_button--right {
    right: 0;

  }
  .carousel_button>svg {
    fill: var(--white);
  }
  .carousel_dots {
    position: relative;
    bottom: -1rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .carousel_dots>i {
    margin: 0rem 0.5rem;
    width: 1rem;
    height: 1rem;
    background-color: var(--blueTranslucent);
    border-radius: 50%;
    transition: ease 0.3s;
  }
  .carousel_dots .selected {
    background-color: var(--blueDarker);
  }
  .dot:hover {
    cursor: pointer ;
    background-color: var(--blueDarker);
  }

  .carousel_banner {
    object-fit: contain;
    max-width: 250px;
    height: 100%;
    max-height: 100px;
  }



  .footer {
    padding: 1rem 0;
    color: var(--white);
    background-color: var(--blue);
    
  }
  .footer__contact,
  .footer__data {
    width: calc(90% - 4rem);
    max-width: calc(600px - 4rem);

    margin: 0 auto 1rem auto;
    padding: 1rem 2rem;
    text-align: justify;

    background-color: var(--blueDarker);
    border-radius: 1rem;
    box-shadow: 0 0 2rem var(--shadow);  
  }
  .footer__media {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(90% - 4rem);
    max-width: calc(600px - 4rem);
    margin: 0 auto 1rem auto;
    padding: 1rem 2rem;

    text-align: justify;

    background-color: var(--blueDarker);
    border-radius: 1rem;
    box-shadow: 0 0 2rem var(--shadow);  
  }
  .footer__icon {
    display: flex;
    justify-content: center;
    width: fit-content;
    height: 2rem;
    fill: white;
    margin: 0 1rem;
  }
.footer__icon--datafiscal{
  width: 2rem;
  height: 3rem;
}

  .footer__icon:hover {
    cursor: pointer;
    fill: aquamarine;
    transform: scale(1.5);
    transition: 0.3s;
  }


  .technical_support {
    margin: 0;
    width: 100%;
    min-height: 80vh;
    padding: 20vh 0;
    
  }

  /* .technical_support__background {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: url("../imgs/abstract.jpg");
  } */

  .technical_support__tittle {
    width: 80%;
    max-width: 600px;
    margin: 0 auto 0 auto;
    text-align: left;
    color: var(--white);

    font-size: 3rem;
    line-height: 4rem;
    text-shadow: 0.5rem 0.5rem 0.5rem var(--blueDarker);
  }

  .technical_support__paragraph {
    position: relative;
    width: 80%;
    max-width: 600px;
    margin: 10vh auto 10vh auto;
    color: var(--white);
    font-size: 1.2rem;
    text-align: justify;
  }
  .technical_support__WhatsAppButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    width: fit-content;

    white-space: nowrap;
    font-size: 1rem;
    text-decoration: none;
    color: inherit;
    fill: var(--white);
    background-color: var(--blue);
    transition: 0.3s;
  }
  .technical_support__WhatsAppButton > svg {
    width: 1rem;
    margin-right: 1rem;
  }

  .technical_support__subtitle{
    width: 80%;
    max-width: 600px;
    margin: 0 auto 1rem auto;
    text-align: left;
    color: var(--white);

    font-size: 2rem;
    line-height: 2rem;
    text-shadow: 0.2rem 0.2rem 0.2rem var(--blueDarker);
  }
  .technical_support__manuals{
    width: 80%;
    max-width: 600px;
    margin: 0 auto 5rem auto;
    text-align: left;
    color: var(--white);
  }
  .technical_support__manuals > p{
    color: var(--white);
  }
  .technical_support__manuals > a{
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    width: fit-content;

    white-space: nowrap;
    font-size: 1rem;
    text-decoration: none;
    color: inherit;
    background-color: var(--blue);
    transition: 0.3s;
  }
  .technical_support__manuals > a:hover, .technical_support__WhatsAppButton:hover{
    cursor: pointer;
    color: var(--blue);
    fill: var(--blue);
    background-color: var(--white);
    transform: scale(1.2);
    box-shadow: 0 0 1rem var(--shadow);
  }

  .technical_support__form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 0 5vh 0;
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 2rem;
    
    justify-content: center;
    line-height: 1.5rem;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
  }

  .technical_support__datos {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    max-width: 600px;
    margin: 2rem auto 0 auto;
    padding: 0 15%;
  }

  .technical_support__datos>h3,
  .technical_support__form>h2 {
    color: var(--white);
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
  }

  .technical_support__form>h2 {
    margin-top: 0;
    padding: 5vh 10%;
    width: 80%;
    color: var(--white);
    background-color: var(--blue);
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;

  }

  .technical_support__datos>h3 {
    margin: 0 0 1rem 0;
  }

  .technical_support__datos>label {
    text-align: left;
    width: 100%;
    color: var(--white);
  }

 .technical_support__datos>label>input {
      text-align: left;
      width: calc( 100% - 2rem );
      height: 2rem;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      padding: 0 1rem;
  

      color: var(--white);
      border: solid 1px transparent;
      border-radius: 0.5rem;
      background-color: rgba(255, 255, 255, 0.1);
      outline: none;
 }
 .technical_support__datos>label>input:focus, .technical_support__textarea>textarea:focus {
  border: solid 1px var(--white);
  background-color: var(--blue);
 }

  .technical_support__textarea {
    text-align: left;
    width: 70%;
    max-width: 600px;
    margin: 0 auto 0 auto;
    padding: 0 15%;
    color: var(--white);
  }

  .technical_support__textarea>textarea {
    width: calc( 100% - 2rem );
    height: 20vh;
    padding: 1rem;
    resize: vertical;
    color: var(--white);
    border: solid 1px transparent;
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 0.1);
    outline: none;
  }
  

  .technical_support__advice {
    width: 80%;
    max-width: 600px;
    padding: 2rem 0;
    margin: 0 auto 0 auto;
    text-align: justify;
    color: var(--whiteTraslucent);
  }
 
  .technical_support__form > button {
    width: 80%;
    max-width: 200px;
    padding: 1rem 2rem;
    margin: 2rem auto 0 auto;

    border: none;
    border-radius: 2rem;
    color: var(--white);
    background-color: var(--blue);
    transition: 0.3s;
  }
  .technical_support__form > button:hover{
    cursor: pointer;
    color: var(--blue);
    background-color: var(--white);
    transform: scale(1.2);
    box-shadow: 0 0 1rem var(--shadow);
  }


  .contact-section{
    padding: 0 0 5vh 0;
    width: 100%;
    /* height: auto; */
    min-height: 95vh;

    color: var(--white);
    
  }
  .contact-section__title{
    padding: calc(3rem + 4rem) 0 4rem 0;
    font-size: 2rem;
  }
  .contact-section__1, .contact-section__2, .contact-section__3, .contact-section__4, .contact-section__5{
    width: 80%;
    max-width: 500px;
    margin: 0 auto 2rem auto;
    padding: 1rem 5%;
    text-align: justify;

    background-color: var(--blueDarker);
    border-radius: 1rem;
    box-shadow: 0 0 2rem var(--shadow);  
  }
  .contact-section__5{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .contact-section__5 > p {
    width: 100%;
    text-align: left;
    margin: 0 0 1rem 0;
  }
  .contact-section__WhatsAppButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    padding: 0.5rem 1rem;
    margin: 1rem auto 0 auto;
    font-size: 2rem;
    text-decoration: none;
    color: var(--blue);
    border-radius: 2rem;
    background-color: var(--whatsAppButton);
    fill: var(--blue);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.36);
    transition: 0.3s;
  }
  .contact-section__WhatsAppButton:hover{
    background-color: var(--whatsAppButtonHover);
    transform: scale(1.2);
    color: var(--blue);
    fill: var(--blue);
}
  .contact-section__WhatsAppButton > p{
    margin-left: 1rem;
    white-space: nowrap;
  }
  .contact-section__map{
    width: 90%;
    max-width: calc(500px + 10%);
    height: 300px;
    border-radius: 1rem;
    box-shadow: 0 0 1rem var(--shadow);
  }
  .contact-section__map > p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0 auto;
    width: 90%;
    max-width: calc(400px + 20%);
    min-height: 20rem;
    height: 20vh;
    font-size: 1.5rem;

    background: url("./img/map.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 0 2rem var(--shadow);
    text-decoration: none;
    color: var(--black);
    border-radius: 1rem;
    transition: 1s;
    padding-bottom: 10vh;
  }
  .letBlueHighlight {
    color: var(--letBlueHighlight);
  }
}

@media (min-width:1101px) and (max-width: 1600px){
  /* .welcome__img{
    height: fit-content;
    width: 25vw;
    position: absolute;
    top: 25vh;
    left: 65%;
    filter: drop-shadow( 0.5rem 0.5rem 0.5rem var(--blueDarker))

  } */
  /* .contact-section__map {
    height: 397px;
  } */
}

@media (min-width: 1601px){
  /* .welcome__img{
    height: fit-content;
    width: 70%;
    position: absolute;
    top: 20vh;
    left: 15%;
    filter: drop-shadow( 0.5rem 0.5rem 0.5rem var(--blueDarker))
  } */
  .contact-section__title{
    margin: 5rem 0 !important;
  }
}

@media (min-width: 1101px) {
  .App{ 
    background: linear-gradient(135deg, var(--linearGradientDarkBlue), var(--linearGradientLetBlue));
    background-size: 100% 100%;
  }

  .header {
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    height: 10vh;
    color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px;

    
  }

  .header--background {
    background-color: var(--shadow);
    backdrop-filter: blur(20px);
  }

  .header__logo {
    margin: 0 0 0 10%;
    height: 50px;
  }


  .navbar {
    display: flex;
    /* flex-wrap: nowrap; */
    align-self: center;
    justify-self: center;
    height: fit-content;
    margin: auto;
  }

  .navbar:hover {
    /* box-shadow: 0 1rem 4rem var(--blueDarker); */
    transition: ease 0.3s;

  }

  .header__hamburger-button{
    display: none;
  }
  .navbar>button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
    background-color: transparent;
    padding: 0.3rem 1rem;
    border: solid 1px var(--whiteTraslucent);
    text-decoration: none;
    color: inherit;
  }
  .navbar>button > svg {
    width: 1rem;
    margin-left: 0.5rem;
    fill: var(--white);
  }

  .navbar>button:hover {
    cursor: pointer;
    background-color: #2f61c4;
    transform: scale(1.2);
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
    transition: ease 0.3s;
  }

  .navbar__left {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .navbar__right {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-right: 5rem;
  }

  .navbar__border-right {
    border-right: solid 1px var(--whiteTraslucent);
  }

  .header__logIn {
    display: flex;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: inherit;
    cursor: pointer;

    background-color: transparent;
    border: solid 1px var(--whiteTraslucent);
    border-radius: 2rem;
    margin-right: 5rem;
    padding: 0.3rem 1rem;
    transition: ease 0.3s;
  }

  .header__logIn:hover {
    /* box-shadow: 0 1rem 2rem var(--blueDarker); */
    background-color: var(--blueHover);
    transform: scale(1.2);
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
  }

  .header__logIn>svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    justify-content: center;
    align-items: center;
    fill: var(--white);
  }
  .header__closeModal{
    display: none !important;
  }


  .welcome {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }
  @keyframes rotation {
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(359deg);
    }
  }

  .welcome__background{
    position: absolute;
    z-index: -1;
    top:0;
    left: 0;
    background:  url(../imgs/abstract.jpg);
    background-size: cover;
    height: 100%;
    width: 100%;
    animation: rotation 60s ease infinite;

  }
  .welcome__img{
    height: fit-content;
    width: 70%;
    position: absolute;
    top: 20vh;
    left: 15%;
    filter: drop-shadow( 0.5rem 0.5rem 0.5rem var(--blueDarker))
  }


  /* @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  } */


  /* .welcome__title {
    position: absolute;
    left: 10%;
    text-align: left;
    color: white;
    font-size: 6rem;
    margin-left: auto;
    padding-top: 25vh;
    width: 60%;
    max-width: 1920px;
    text-shadow: 0.5rem 0.5rem 0.5rem var(--blueDarker);
  } */

  .presentation {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    margin: 100vh auto 10vh auto;
    width: 100vw;
    justify-content: center;
    align-content: center;
    color: var(--white);
  }

  .presentation__text {
    width: 60%;
    max-width: 1000px;
    text-align: justify;
    font-size: 1.5rem;
    hyphens: auto;
    /* line-height: 1rem; */
    margin: 0 auto 2rem auto;
  }
  h3.presentation__text {
    margin-top: 10vh;
    font-size: 2rem;
  }
  .presentation__video {
    width: 60%;
    max-width: 1000px;
    margin-top: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
  }
  .presentation__afip-img {
    width: 60%;
    max-width: 1000px;
    height: fit-content;
    margin-top: 10rem;
    border-radius: 1rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
  }


  .carousel {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    max-width: 1000px;
    height: 25vh;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  .carousel_button {
    position: absolute;
    bottom: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 3rem;
    width: 3rem;

    background-color: var(--blueTranslucent);
    color: var(--white);
    border: none;
    border-radius: 50%;
    transition: ease 0.2s;
    z-index: 2;

  }

  .carousel_button:hover {
    cursor: pointer;
    background-color: var(--blueDarker);
    /* color: var(--blueDarker); */
  }

  .carousel_button--left {
    left: 0
  }

  .carousel_button--right {
    right: 0;

  }

  .carousel_button>svg {
    fill: var(--white);
  }

  .carousel_dots {
    position: relative;
    bottom: -1rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .carousel_dots>i {
    margin: 0rem 0.5rem;
    width: 1rem;
    height: 1rem;
    background-color: var(--blueTranslucent);
    border-radius: 50%;
    transition: ease 0.3s;
  }

  .carousel_dots .selected {
    background-color: var(--blueDarker);
  }

  .dot:hover {
    cursor: pointer ;
    background-color: var(--blueDarker);
  }

  .carousel_banner {
    object-fit: contain;
    max-width: 500px;
    height: 70%;
    max-height: 150px;
  }






  .footer {
    width: 100%;
    padding: 2rem 0;
    background-color: var(--shadow);
    backdrop-filter: blur(10px);
  }

  .footer__background {
    display: flex;
    line-height: 1.5;
    width: 100%;
    justify-content: center;
    text-align: justify;
    color: var(--white);
  }

  .footer__contact {
    width: 30%;
    /* max-width: 500px; */
  }

  .footer__data {
    width: 30%;
    /* max-width: 500px; */
  }

  .footer__media {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer__icon {
      display: flex;
      justify-content: center;
      width: fit-content;
      height: 3rem;
      fill: white;
      margin: 0 1rem;
  }
  .footer__icon--datafiscal{
    width: 2rem !important;
  }

  /* .footer__icon:hover {
    fill: aquamarine;
    transform: scale(1.2);
    transition: 0.3s;
  } */
  .footer__icon > svg:hover, .footer__icon > img:hover{
    cursor: pointer;
    fill: aquamarine;
    transform: scale(1.5);
    transition: 0.3s;
  }



  .technical_support {
    position: relative;
    margin: 0;
    width: 100%;
    padding-bottom: 5vh;
    /* background: linear-gradient(135deg, var(--linearGradientDarkBlue), var(--linearGradientLetBlue));
    background-size: cover; */
    overflow: hidden;
  }

  .technical_support__background {
    position: absolute;
    z-index: -2;
    top:0;
    left: 0;
    background:  url(../imgs/abstract.jpg);
    background-size: cover;
    
    height: 100%;
    width: 100%;
    animation: rotation 60s ease infinite;

  }


  .technical_support__tittle {
    width: 60%;
    margin: 0 auto;
    padding: 20vh 0 10vh 0;
    text-align: left;
    color: var(--white);

    font-size: 4rem;
    line-height: 4rem;
    text-shadow: 0.5rem 0.5rem 0.5rem var(--blueDarker);
  }

  .technical_support__paragraph {
    /* position: relative; */
    display: flex;
    width: 60%;
    max-width: 1000px;
    margin: 0vh auto 20vh auto;
    color: var(--white);
    font-size: 1rem;
    text-align: justify;
  }
  .technical_support__paragraph > p{
    font-size: inherit;
  }
  .technical_support__paragraph > p > b {
    font-size: inherit;
  } 
  .letBlueHighlight{
    color: var(--letBlueHighlight);
  }
  .technical_support__WhatsAppButton{
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0.5rem 1rem;
    height: fit-content;
    color: var(--white);
    background-color: var(--blue);
    border-radius: 2rem;
    margin-left: 2rem;
    /* box-shadow: 0 0 1rem var(--shadow); */
    transition: 0.3s;
    text-decoration: none;

    fill: var(--white)
  }
  .technical_support__WhatsAppButton > p {
    text-decoration: none;
    color: inherit;
  }
  .technical_support__WhatsAppButton > svg {
    width: 1rem;
    margin-right: 0.5rem;
    /* height: fit-content; */
    fill: inherit;
  }
  .technical_support__WhatsAppButton:hover{
   background-color: var(--white);
   fill: var(--blue);
   color: var(--blue);
   scale: 1.2;
   box-shadow: 0 0 1rem var(--shadow);

  }
  

  .technical_support__subtitle {
    padding: 0;
    width: 60%;
    max-width: 1000px;
    margin: 0 auto;

    font-size: 2rem;
    color: var(--white);
    text-shadow: 2px 2px 5px var(--black);
  }
  .technical_support__subtitle--margin-bottom{
    margin-bottom: 1rem !important;
  }

  .technical_support__manuals {
    display: flex;
    align-items: center;
    padding: 0;
    width: 60%;
    max-width: 1000px;
    margin: 0.5rem auto 10vh auto;

    font-size: 1rem;
    color: var(--white);
  }
  .technical_support__manuals > a {
    display: inline-block;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    width: fit-content;

    white-space: nowrap;
    font-size: 1rem;
    text-decoration: none;
    color: inherit;
    background-color: var(--blue);
    transition: 0.3s;
  }
  .technical_support__manuals > a:hover{
    cursor: pointer;
    color: var(--blue);
    background-color: var(--white);
    transform: scale(1.2);
    box-shadow: 0 0 1rem var(--shadow);
  }


  .technical_support__form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 0 5vh 0;
    width: 60%;
    max-width: 1000px;
    margin: 0 auto;
    border-radius: 2rem;
    
    justify-content: center;
    line-height: 1.5rem;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.3);
  }

  .technical_support__datos {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    max-width: 600px;
    margin: 2rem auto 0 auto;
    padding: 0 15%;
  }

  .technical_support__datos>h3,
  .technical_support__form>h2 {
    color: var(--white);
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
  }

  /* .technical_support__form>h2 {
    margin-top: 0;
    padding: 5vh 10%;
    width: 80%;
    color: var(--white);
    background-color: var(--blue);
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;

  } */

  .technical_support__datos>h3 {
    margin: 0 0 1rem 0;
  }

  .technical_support__datos>label {
    text-align: left;
    width: 100%;
    color: var(--white);
  }

 .technical_support__datos>label>input {
      text-align: left;
      width: calc(100% - 2rem);
      height: 2rem;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      padding: 0 1rem;
  
      color: var(--white);
      border: solid 1px transparent;
      /* border: solid 1px var(--whiteTraslucent); */
      border-radius: 0.5rem;
      background-color: rgba(255, 255, 255, 0.1);
      outline: none;
 }
 .technical_support__datos>label>input:focus{
  border: solid 1px var(--white);
  background-color: var(--blue);
 }
  .technical_support__textarea {
    text-align: left;
    width: 70%;
    max-width: 600px;
    margin: 0 auto 0 auto;
    padding: 0 15%;

    color: var(--white);
  }

  .technical_support__textarea>textarea {
    width: calc(100% - 2rem);
    height: 20vh;
    padding: 1rem;
    resize: vertical;

    color: var(--white);
    border: solid 1px transparent;
    border-radius: 1rem; 
    background-color: rgba(255, 255, 255, 0.1);
    outline: none;
  }
  .technical_support__textarea>textarea:focus{
    border: solid 1px var(--white);
    background-color: var(--blue);
  }
  
  .technical_support__form > button {
    width: 50%;
    min-width: fit-content;
    padding: 1rem 2rem;
    margin: 2rem auto 2rem auto;

    border: none;
    border-radius: 2rem;
    color: var(--white);
    background-color: var(--blue);
    transition: 0.3s;

  }
  .technical_support__form > button:hover{
    cursor: pointer;
    color: var(--blue);
    background-color: var(--white);
    transform: scale(1.2);
    box-shadow: 0 0 1rem var(--shadow);
  }
  .technical_support__advice {
    width: 80%;
    max-width: 600px;
    margin: 0 auto 0 auto;
    text-align: justify;
    color: var(--whiteTraslucent);
  }
 



  .news {
    background-color: var(--whiteBackground);
    /* padding-top: 40vh; */
  }

  .news__banner {
    width: 100%;
    height: 20vh;
    padding-top: 0vh;

    /* margin-top: 20vh; */
    background-color: var(--blue);
  }

  .news__title {
    /* padding: 5vh 0; */
    color: var(--white);
    font-size: 5rem;
    line-height: 5rem;

  }

  .news__section-selector {
    width: 100%;
    height: 7vh;
    padding-top: 1vh;
    background-color: var(--blueDarker);
  }

  .news__section-selector>button {
    width: 20%;
    height: 7vh;
    font-size: 1.5rem;
    color: var(--white);
    background-color: var(--blueDarker);
    border: solid 1px var(--blueDarker);
    border-bottom: none;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .news__section-selector>button:hover {
    background-color: var(--whiteTraslucent);
  }

  .news__section-selector--selected {
    background-color: var(--whiteBackground) !important;
    color: var(--blueDarker) !important;
  }


  .cart-container {
    padding: 0 10vw;
  }

  .cart {
    display: flex;
    flex-flow: column;
    width: 150px;
    height: 200px;
    padding: 1rem;
    background-color: gray;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    box-shadow: 0 0 0.5rem black;
  }

  .cart>img {
    width: 100%;
  }


  #contact-section {
    position: relative;
    width: 100%;
    min-height: 85vh;
    padding: 10vh 0 5vh 0;

    color: var(--white);
    /* background: linear-gradient(135deg, var(--linearGradientDarkBlue), var(--linearGradientLetBlue)); */
    /* background-size: 100% 100%; */
    overflow: hidden;
  }

  .contact__background{
    position: absolute;
    z-index: -2;
    top:0;
    left: 0;
    background:  url(../imgs/abstract.jpg);
    background-size: cover;
    height: 100%;
    width: 100%;
    animation: rotation 60s linear infinite;
  }

  .contact-section__title {
    font-size: 3rem;
    text-align: center;
    margin: 2rem 0 3rem 0;
  }

  .contact-section__grid-container{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    gap: 8px;

    margin-top: 1rem;
  }
  .contact-section__1, .contact-section__2, .contact-section__3, .contact-section__4{
    text-align: justify;
    padding: 1rem;

    border-radius: 1rem;
    background-color: var(--blue);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.342);
  }
  .contact-section__1 {
    grid-column: 2/4;
    grid-row: 1;
  }

  .contact-section__2 {
    grid-column: 2/4;
    grid-row: 2;
  }
  .contact-section__3 {
    grid-column: 2/4;
    grid-row: 3;
  }
  .contact-section__4 {
    grid-column: 2/4;
    grid-row: 4;
  }

  .contact-section__5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: space-between; */
    text-align: left;
    grid-column: 2/4;
    grid-row: 5;
    padding: 1rem;

    border-radius: 1rem;
    background-color: var(--blue);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.342);
  }
  .contact-section__5 > p {
    width: 100%;
    text-align: left;
    margin: 0 0 1rem 0;
    padding: 0;
  }
  .contact-section__WhatsAppButton{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 15rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem auto 0 auto;
    font-size: 1rem;
    text-decoration: none;
    color: var(--blue);
    border-radius: 2rem;
    background-color: var(--whatsAppButton);
    fill: var(--blue);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.36);

    transition: 0.3s;
  }
  .contact-section__WhatsAppButton:hover{
    background-color: var(--whatsAppButtonHover);
    transform: scale(1.2);
    color: var(--blue);
    fill: var(--blue);
}
  .contact-section__WhatsAppButton > svg{
    width: 1.5rem;
  }
  
  .contact-section__WhatsAppButton > p{
    margin-left: 1rem;
    font-size: 1rem;
  }

  .contact-section__letBlueHighlight {
    color: #72beda;
  }

  .contact-section__map {
    grid-column: 4/6;
    grid-row: 1/6;
    height: 100%;
    width: 100%;
    text-decoration: none;
    border-radius: 1rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.342);

  }

  .contact-section__map>p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    font-size: 1.5rem;
    text-decoration: none;
    color: var(--white);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    transition: 1s;
  }

  .contact-section__map>p:hover {
    opacity: 0;
    transition: opacity 0.5s;
  }
}